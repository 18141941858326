import { Button, Card, Col, Row, Typography } from 'antd';
import { graphql, Link, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import Container from '@/components/Container';
import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import { ArticlesType } from '@/types';
import { findImageByBase } from '@/utils';

const ArticlesPage: React.FC<
  PageProps & {
    data: {
      allFile: { nodes: any[] };
      allArticlesJson: { nodes: { node: ArticlesType }[] };
    };
  }
> = ({
  uri,
  data: {
    allArticlesJson: { nodes: articles },
    allFile: { nodes: articlesImages },
  },
}) => (
  <Layout>
    <SEO
      title="Статьи"
      description="Здесь вы можете узнать дополнительную информацию о нашей косметической продукции, её компонентах и многое другое."
      uri={uri}
      image={null}
    />
    <Typography.Title level={1} className="my-8 text-center">
      Статьи
    </Typography.Title>
    <Container>
      <Row gutter={32}>
        {articles.map((i: any, idx) => {
          const image = findImageByBase(articlesImages, i.imageName);

          return (
            <Col xs={24} sm={12} md={8} key={idx} className="mb-8">
              <Link to={i.slug}>
                <Card
                  hoverable
                  cover={<GatsbyImage image={image as any} alt={i.title} />}
                >
                  <Card.Meta
                    title={i.title}
                    description={
                      <Typography.Paragraph ellipsis={{ rows: 3 }}>
                        {i.content[0].text}
                      </Typography.Paragraph>
                    }
                  />
                  <Button type="primary" block>
                    Подробнее
                  </Button>
                </Card>
              </Link>
            </Col>
          );
        })}
      </Row>
    </Container>
  </Layout>
);

export default ArticlesPage;

export const pageQuery = graphql`
  query ArticlesIndex {
    allFile(filter: { sourceInstanceName: { eq: "articles" } }) {
      nodes {
        id
        base
        childImageSharp {
          gatsbyImageData(height: 400)
        }
      }
    }
    allArticlesJson {
      nodes {
        id
        imageName
        slug
        title
        content {
          text
        }
      }
    }
  }
`;
